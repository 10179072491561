import { Box } from "@material-ui/core";

export const Home: React.FC = () => {
  return (
    <Box
      paddingTop="100px"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      Home
    </Box>
  );
};
