import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Login } from "./pages/Login";
import { Home } from "./pages/Home";
import { ProtectedRoute } from "./utils/protectedRoute";
import { Post } from "./pages/Post";

export const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <ProtectedRoute exact path="/home" component={Home} />
        <ProtectedRoute exact path="/post" component={Post} />

        <Route path="/" component={Login} />
      </Switch>
    </BrowserRouter>
  );
};
