import { Redirect, Route } from "react-router-dom";
import { Menu } from "../components/Menu";
import { useAuth } from "../utils/AuthProvider";

export const ProtectedRoute = (props: any) => {
  const { currentUser } = useAuth();

  return currentUser ? (
    <>
      <Menu />
      <Route {...props} />
    </>
  ) : (
    <Redirect
      to={{
        pathname: "/",
      }}
    />
  );
};
