import ReactDOM from "react-dom";
import { Router } from "./router";
import "./utils/firebase";
import { AuthProvider } from "./utils/AuthProvider";
import "./styles/index.css";

const App = () => {
  return (
    <AuthProvider>
      <Router />
    </AuthProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
