import { Box, Button, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  createPost,
  getAllPosts,
  getMyPosts,
  postData,
  PostInterface,
} from "./servicePost";
import { useAuth } from "../../utils/AuthProvider";

export const Post: React.FC = () => {
  const { currentUser } = useAuth();

  const [allPosts, setAllPosts] = useState<PostInterface[]>([]);
  const [myPosts, setMyPosts] = useState<PostInterface[]>([]);

  useEffect(() => {
    const unsubscribeAllPosts = getAllPosts().onSnapshot((snap) => {
      let allDocs: any = [];
      snap.forEach((item) => allDocs.push({ ...item.data(), id: item.id }));
      setAllPosts([...allDocs]);
    });

    const unsubscribeMyPosts = getMyPosts(currentUser.uid).onSnapshot(
      (snap) => {
        let myDocs: any = [];
        snap.forEach((item) => myDocs.push({ ...item.data(), id: item.id }));
        setMyPosts([...myDocs]);
      }
    );
    return () => {
      unsubscribeMyPosts();
      unsubscribeAllPosts();
    };
  }, []);

  const handleNewPost = () => {
    const data = postData(currentUser);
    if (data) {
      createPost(data);
    }
  };

  return (
    <Box
      paddingTop="100px"
      height="100%"
      display="flex"
      flexDirection="column"
      gridGap="10px"
      alignItems="center"
      justifyContent="center"
    >
      Post
      <Box display="flex" gridGap="25px">
        <Button variant="contained" onClick={() => handleNewPost()}>
          Create Post
        </Button>
      </Box>
      <Box display="flex" gridGap={50}>
        <Box minWidth="20vw">
          <Typography variant="h5" gutterBottom>
            Meus posts {myPosts.length}
          </Typography>
          <ul>
            {myPosts.map((post) => (
              <li key={post.id}>
                {post.id} - {post.title}
              </li>
            ))}
          </ul>
        </Box>
        <Box minWidth="20vw">
          <Typography variant="h5" gutterBottom>
            Todos {allPosts.length}
          </Typography>
          <ul>
            {allPosts.map((post) => (
              <li key={post.id}>
                {post.id} - {post.title}
              </li>
            ))}
          </ul>
        </Box>
      </Box>
    </Box>
  );
};
