import firebase, { db } from "../../utils/firebase";

const postsRef = db.collection("posts");

export interface PostInterface {
  id?: string;
  title: string;
  active: boolean;
  likes: number;
  createdAt: any;
  tags: string[];
  author: string;
}

export const postData = (currentUser: firebase.User): PostInterface => {
  return {
    title: "Hello world!",
    active: true,
    likes: 0,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    tags: ["world", "hello"],
    author: currentUser.uid,
  };
};

export const getAllPosts = () => {
  return postsRef.where("active", "==", true);
};

export const getMyPosts = (userId: string) => {
  return postsRef.where("author", "==", userId);
};

export const createPost = (post: PostInterface) => {
  return postsRef.add(post);
};
