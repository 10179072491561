import { Box, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FormEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../../utils/firebase";

export const Login: React.FC = () => {
  const history = useHistory();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      // await auth.createUserWithEmailAndPassword(email, password);
      await auth.signInWithEmailAndPassword(email, password);
      history.push("/home");
    } catch {
      setError("Tem erro!");
    }
    setLoading(false);
  };

  return loading ? (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      Loading...
    </Box>
  ) : (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <form onSubmit={(e) => handleSubmit(e)}>
        <Box
          width="30vw"
          minWidth="250px"
          display="flex"
          flexDirection="column"
          gridGap="25px"
          bgcolor="#FFF"
          padding={3}
          borderRadius={10}
        >
          <TextField
            required
            label="Email"
            variant="outlined"
            fullWidth={true}
            type="email"
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <TextField
            required
            label="Password"
            variant="outlined"
            fullWidth={true}
            type="password"
            onChange={(e: any) => setPassword(e.target.value)}
          />

          <Button
            color="primary"
            variant="contained"
            fullWidth={true}
            type="submit"
          >
            Login
          </Button>
        </Box>
      </form>
    </Box>
  );
};
